<template>
    <div class="wrapper">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    中国平安保险说明
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class="content">
            <h3>保险说明</h3>
            一、个人第三方责任险
            <p>1.保险对象：上门服务的护士</p>
            <p>2.保险受益人：患者</p>
            <p>3.保险公司：中国平安</p>
            <p>4.保险内容：</p>
            <p>a）在保险期间内，上门服务的护士因过失导致患者人身伤亡或财产的直接损失，依照中华人民共和国法律（不包括港澳台地区法律）应由护士承担的经济赔偿责任，保险人按照本保险合同约定负责赔偿。</p>
            <p>
                b）在保险期间内，由上门服务的护士作为监护人的10周岁以下的未成年人造成第三者人身伤亡或财产的直接损失，依照中华人民共和国法律（不包括港澳台地区法律）应由该护士承担的经济赔偿责任，保险人按照本保险合同约定负责赔偿。</p>
            <p>
                c）保险事故发生后，上门服务的护士因保险事故而被提起仲裁或者诉讼的，对应由该护士支付的仲裁或诉讼费用以及事先经保险人书面同意支付的其他必要的、合理的费用（以下简称“法律费用”），保险人按照本保险合同约定也负责赔偿</p>
            <p>5.赔付金额：</p>
            <p>a）护士过失导致患者死亡的赔付50000元</p>
            <p>b）护士过失导致患者财产损失最高赔付限额2万元，其中单件物品损坏最高800元，赔付比例80%，易碎品赔付比例50%</p>
            <p>c）其他具体情况由保险公司进行评定后保险金额范围为0-50000元</p>
            <p>二、意外伤残身故险 1. 保险对象：提供上门服务的护士 2. 保险受益人：该护士 3. 保险公司：中国人保LOGO+中国平安LOGO 4.
                保险内容：服务过程中遭受的意外伤害，包含意外身残，意外身故和因此导致的医疗支出的费用；意外伤害是指以外来的、突发的、非本意的、非疾病的客观事件为直接且单独的原因致使身体受到的伤害
                a）在服务过程中包含上门服务的路程中，意外刮伤、意外车祸、意外烫伤等外来的、突发的、非疾病的的事件）所造成的死亡、伤残或身体受伤所产生的医疗费用
                b）在服务过程中，不小心滑倒、摔倒、磕伤、碰伤、烫伤、烧伤导致死亡或残疾的情况。 c）在服务过程中，遭遇地震、火灾、爆炸，导致死亡或残疾的情况。
                d）在服务地址内且在服务过程中,遭受的客户的强暴，拘禁，性侵，杀害等情况造成的身残或身故
                e）在保险期间内，被保险人遭受意外伤害事故，并因此在符合保险合同第二十六条释义的医院（以下简称“释义医院”）进行治疗，保险人就其自事故发生之日起180内实际支出的按照当地社会医疗保险主管部门规定可报销的、必要、合理的医疗费用给付医疗保险金
                f）其他意外情况，由保险公司根据具体情况进行调查评估而定 5.保险金额： a） 意外身故和严重的意外身残可获赔金额400000元 b） 意外医疗保险（免赔额200元）最高可获得10000元
                c）其他具体情况由保险公司进行评定后保险金额范围为0-400000元</p>

            <p>【保险对象】</p>
            <p>“医护到家”平台提供上门服务的医护人员。</p>

            <p>【保险期间】</p>
            <p>“医护到家”平台医护人员当天执行第一单任务起至当天24时止。</p>

            <p>具体保障方案如下：</p>

            【保险项目】
            <table width="100%" cellspacing="0" cellpadding="0" border="1">
                <tbody>
                <tr>
                    <td><span class="span_black">保险项目</span></td>
                    <td>保障方案</td>
                    <td>保险金额</td>
                </tr>
                <tr>
                    <td rowspan="2">意外伤害保险</td>
                    <td>意外身故、残疾保险</td>
                    <td>100，000</td>
                </tr>
                <tr>
                    <td>意外医疗保险（免赔额200元）</td>
                    <td>100，000</td>
                </tr>
                <tr>
                    <td>个人第三者责任保险 </td>
                    <td>第三者人生意外伤害和第三者财产损失（免赔额200元）</td>
                    <td>50，000（其中第三者财产损失最高赔付限额2万元）</td>
                </tr>
                <tr>
                    <td>保费</td>
                    <td></td>
                    <td>由医护到家平台承担</td>
                </tr>
                </tbody></table>


            <p>特别约定：</p>
            <p>1、该产品方案仅适用承保通过“医护到家”网络平台接单的医护人员和在“医护到家”网络平台 实名注册并使用服务的用户,在订单服务过程中发生的意外伤害事故及个人责任事故;</p>

            <p>2、本保险只承担年龄在18-65周岁被保险人的保险责任；</p>
            <p>3、第三者责任保险条款中造成第三者财产损失最高赔付限额2万元，免赔额200元；三者财产损失中，单件物品损坏最高800元，赔付比例80%，，易碎品赔付比例50%；</p>
            <p>4、特约家财损失除外责任：金银、珠宝首饰或饰物、移动电话（或称手机、蜂窝电话）、手提电脑（或称手提便携式电脑、笔记本电脑）或个人商务助理设备（或称掌上电脑、PDA），图章、文件，用于商业活动的物品或样品，动物、植物或食物，机动车辆（及其附件）、摩托车、船、发动机或其他运输工具，家具、古董、字画等无法确定价值的物品，被保险人从他人租赁的设备，眼镜、隐形眼镜、助听器和假肢的遗失或损坏。</p>
            <p>【理赔流程】</p>
            <p>保险理赔流程:</p>
            <p>1、单方事故:</p>
            <p>(1)拨打平安产险专属服务热线,吴先生,17721012611;</p>
            <p>(2)待平安产险理赔人员回访并跟进后续理赔事宜;</p>
            <p>2、多方/到家事故:</p>
            <p>(1)事故发生后第一时间拨打 110 报警电话并获取官方事故责任认定书；</p>
            <p>(2) 拨打平安产险专属服务热线,吴先生,17721012611;</p>
            <p>(3)待平安产险理赔人员回访并跟进后续理 赔事宜。</p>
            <p>【理赔时效】</p>
            <p>理赔金额 1 万元以下,资料齐全 5-10 个工作日结案；理赔金额 1 万元以上,可能涉及案件调查,调查周期 5 个工作日,调查结束资料齐全 5-10 个工作 日结案；</p>

            <p>意外伤害保险责任</p>

            <p>【保险金额】</p>
            <table width="100%" cellspacing="0" cellpadding="0" border="1">
                <tbody>
                    <tr>
                        <td><span class="span_black">意外身故、残疾赔付限额</span></td>
                        <td>100，000元</td>
                    </tr>
                    <tr>
                        <td rowspan="2">意外医疗赔付限额（免赔额200元）</td>
                        <td>10，000元</td>

                    </tr>
                </tbody>
            </table>

            <p>【保险期间】</p>
            <p>自被保险人网上订单服务时间起，至服务订单结束时间止。订单起始时间最早不得超出当日凌晨零时，最晚不得晚于当日的二十四时。</p>
            <p>【保险责任】</p>
            <p> 在保险期间内，被保险人因遭受意外伤害事故导致身故、伤残或医疗费用支出的，保险人依照下列约定给付保险金，且身故保险金及伤残保险金累计给付不超过意外伤害保险金额，医疗保险金累计给付不超过意外伤害医疗保险金额。</p>
            <p>(一)身故保险责任</p>
            <p>在保险期间内，被保险人遭受意外伤害事故，并自事故发生之日起180日内因该事故身故的，保险人按意外伤害保险金额给付身故保险金，对该被保险人的保险责任终止。</p>
            <p>被保险人因遭受意外伤害事故且自该事故发生日起下落不明，后经人民法院宣告死亡的，保险人按意外伤害保险金额给付身故保险金。但若被保险人被宣告死亡后生还的，保险金受领人应于知道或应当知道被保险人生还后30日内退还保险人给付的身故保险金。</p>
            <p>被保险人身故前保险人已给付第（二）款约定的伤残保险金的，身故保险金应扣除已给付的伤残保险金。</p>
            <p>(二)伤残保险责任</p>
            <p>在保险期间内，被保险人遭受意外伤害事故，并自该事故发生之日起180日内因该事故造成《人身保险伤残评定标准及代码》（标准编号为JR/T0083—2013，下简称《伤残评定标准》）所列伤残之一的，保险人按该表所列给付比例乘以意外伤害保险金额给付伤残保险金。如第180日治疗仍未结束的，按当日的身体情况进行伤残鉴定，并据此给付伤残保险金。</p>
            <p>1、当同一保险事故造成两处或两处以上伤残时，应首先对各处伤残程度分别进行评定，如果几处伤残等级不同，以最重的伤残等级作为最终的评定结论；如果两处或两处以上伤残等级相同，伤残等级在原评定基础上最多晋升一级，最高晋升至第一级。同一部位和性质的伤残，不应采用《伤残评定标准》条文两条以上或者同一条文两次以上进行评定；</p>
            <p>2、被保险人如在本次意外伤害事故之前已有伤残，保险人按合并后的伤残程度在《伤残评定标准》中所对应的给付比例给付伤残保险金，但应扣除原有伤残程度在《伤残评定标准》所对应的伤残保险金。</p>
            <p>在保险期间内，前述第（一）、（二）款下的保险金累计给付金额以保险单载明的意外伤害保险金额为限。</p>
            <p>（三）医疗保险责任</p>
            <p>在保险期间内，被保险人遭受意外伤害事故，并因此在符合本保险合同第二十六条释义的医院（以下简称“释义医院”）进行治疗，保险人就其自事故发生之日起180内实际支出的按照当地社会医疗保险主管部门规定可报销的、必要、合理的医疗费用给付医疗保险金。</p>
            <p>被保险人无论一次或多次遭受意外伤害事故，保险人均按上述规定分别给付意外伤害医疗保险金，但累计给付金额以不超过该被保险人的意外伤害医疗保险金额为限，累计给付金额达到其意外伤害医疗保险金额时，对该被保险人的保险责任终止。</p>
            <p>如被保险人支出的医疗费已部分得到被保险人所在单位报销或社会医疗保险管理部门给付的，保险人将根据医疗费凭证复印件及单位、社会医疗保险管理部门出具的相关报销或给付金额证明，按规定承担其剩余部分的赔付责任。</p>
            <p>【主要责任免除】</p>
            <p>1、因下列原因造成被保险人身故、伤残或医疗费用支出的，保险人不承担给付保险金责任：</p>
            <p>（一）投保人的故意行为；</p>

            <p>（二）被保险人自致伤害或自杀，但被保险人自杀时为无民事行为能力人的除外；</p>
            <p>（三）因被保险人挑衅或故意行为而导致的打斗、被袭击或被谋杀；</p>

            <p>（四）被保险人妊娠、流产、分娩、疾病、药物过敏、中暑、猝死；</p>
            <p>（五）被保险人接受整容手术及其他内、外科手术；</p>
            <p>（六）被保险人未遵医嘱，私自服用、涂用、注射药物；</p>
            <p>（七）核爆炸、核辐射或核污染；</p>
            <p>（八）被保险人犯罪或拒捕；</p>
            <p>（九）被保险人从事高风险运动或参加职业或半职业体育运动。</p>
            <p>2、被保险人在下列期间遭受伤害导致身故、伤残或医疗费用支出的，保险人也不承担给付保险金责任：</p>

            <p>（一）战争、军事行动、暴动或武装叛乱期间；</p>

            <p>（二）被保险人醉酒或毒品、管制药物的影响期间；</p>


            <p>（三）被保险人酒后驾车、无有效驾驶证驾驶或驾驶无 有效行驶证的机动车期间。</p>
            <p>3、下列费用,保险人不负给付保险金责任:</p>

            <p>（一）保险单签发地社会医疗保险或其他公费医疗管理 部门规定的自费项目和药品费用；</p>

            <p>（二）因椎间盘膨出和突出造成被保险人支出的医疗费用;；</p>

            <p>（三）营养费、康 复费、辅助器 具费、整容费 、美容费、 修复手术费、 牙齿整形费、 牙齿修复 费 、 镶 牙费、护理费、交通费、伙食费、误工费、丧葬 费。</p>
            <p>发生上述第1、2条情形，被保险人身故的，保险人对该被保险人保险责任终止，并对投保人按日计算退还未满期净保费。</p>

            <p>【理赔材料】</p>
            <p>意外伤害身故理赔材料:</p>
            <p>1、 理赔申请书；</p>
            <p>2、所有受益人的身份证明；</p>
            <p>3、受益人资格确认表；</p>
            <p>4、被保险人户籍注销证明、尸体火化证明；</p>
            <p>5、 公安部门或保险人认可的医疗机构出具的被保险人死亡证明书；</p>
            <p>6、意外事故证明；</p>
            <p>7、受益人所能提供的与确认保险事故的性质、原因等有关的其他证明和资料；</p>
            <p>8、受益人与被保险人的关系证明(未指定受益人时,被保险人的父母、配偶、子女(如未成年由 监护人领款)作为第一顺序继承人成为受益人。其中有人先于被保险人去世的,则要提交该人的死 亡证明)；</p>
            <p>9、医护到家平台相关订单信息。</p>
            <p>意外伤害伤残理赔材料:</p>
            <p>1、理赔申请书；</p>
            <p>2、被保险人身份证明(与投保时使用的证件号码一致)；</p>
            <p>3、意外事故证明；</p>
            <p>4、二三级公立医院出具的急诊病历、出院小结、检查报告等医疗材料；</p>
            <p>5、保险公司认可的鉴定机构出具的残疾程度鉴定书(需按合同约定标准评残)；</p>
            <p>6、医护到家平台相关订单信息；</p>
            <p>7、保险金申请人所能提供的与确认保险事故的性质、原因、伤害程度等有关的其他证明和材料。</p>
            <p>意外医疗理赔材料:</p>
            <p>1、理赔申请书；</p>
            <p>2、被保险人身份证明(与投保时使用的证件号码一致)；</p>
            <p>3、医疗机构出具的病历、诊断证明、检查报告等医疗证明；</p>
            <p>4、医疗费用发票原件,费用清单；</p>
            <p>5、意外事故证明；</p>
            <p>6、医护到家平台相关订单信息。</p>

            <p>个人第三者责任保险责任</p>
            <p>【保险金额】</p>
            <table width="100%" cellspacing="0" cellpadding="0" border="1">
                <tbody>
                <tr>
                    <td><span class="span_black">第三者人身伤害＋第三者财产损失（免赔额200元）</span></td>
                    <td>50，000元（其中第三者财产损失最高赔付上限20，000元）</td>
                </tr>

                </tbody>
            </table>

            <p>【保险期间】</p>
            <p>自被保险人网上订单服务时间起，至服务订单结束时间止。订单起始时间最早不得超出当日凌晨零时，最晚不得晚于当日的二十四时。</p>
            <p>【特别约定】</p>
            <p>意外伤害伤残理赔材料:</p>
            <p>1、造成第三者财产损失最高赔付限额2万元，免赔额200元；三者财产损失中，单件物品损坏最高800元，赔付比例80%，，易碎品赔付比例50%；</p>
            <p>2、特约家财损失除外责任：金银、珠宝首饰或饰物、移动电话（或称手机、蜂窝电话）、手提电脑（或称手提便携式电脑、笔记本电脑）或个人商务助理设备（或称掌上电脑、PDA），图章、文件，用于商业活动的物品或样品，动物、植物或食物，机动车辆（及其附件）、摩托车、船、发动机或其他运输工具，家具、古董、字画等无法确定价值的物品，被保险人从他人租赁的设备，眼镜、隐形眼镜、助听器和假肢的遗失或损坏。</p>
            <p>【保险责任】</p>
            <p>在保险期间内，被保险人在中华人民共和国境内（不包括港澳台地区）因过失导致第三者人身伤亡或财产的直接损失，依照中华人民共和国法律（不包括港澳台地区法律）应由被保险人承担的经济赔偿责任，保险人按照本保险合同约定负责赔偿。</p>
            <p>在保险期间内，在中华人民共和国境内（不包括港澳台地区），由被保险人作为监护人的10周岁以下的未成年人造成第三者人身伤亡或财产的直接损失，依照中华人民共和国法律（不包括港澳台地区法律）应由被保险人承担的经济赔偿责任，保险人按照本保险合同约定负责赔偿。</p>
            <p>保险事故发生后，被保险人因保险事故而被提起仲裁或者诉讼的，对应由被保险人支付的仲裁或诉讼费用以及事先经保险人书面同意支付的其他必要的、合理的费用（以下简称“法律费用”），保险人按照本保险合同约定也负责赔偿。</p>
            <p>【主要责任免除】</p>
            <p>1、下列原因造成的损失、费用和责任，保险人不负责赔偿：</p>
            <p>（一）被保险人的故意行为或重大过失行为；</p>
            <p>（二）被保险人的生产、经营、商业、职业、职务行为，以及被保险人提供的产品或服务。</p>
            <p>（三）战争、敌对行动、军事行为、武装冲突、罢工、骚乱、暴动、恐怖活动；</p>
            <p>（四）核辐射、核爆炸、核污染及其他放射性污染；</p>
            <p>（五）大气污染、土地污染、水污染及其他各种污染；</p>
            <p>（六）行政行为或司法行为。</p>
            <p>2、下列损失、费用和责任，保险人不负责赔偿：</p>
            <p>（一）被保险人对其家庭成员、家庭雇佣人员、暂居人员的人身伤亡及其所有或管理的财产损失的责任；</p>
            <p>（二）被保险人在精神错乱、神智不清、意识不清或智障状态下所造成的第三者人身伤亡、财产损失，不论该状态由何原因（包括但不限于疾病、服用药品或毒品、醉酒等）引起；</p>
            <p>（三）未成年人在被保险人或与其共同居住的成年家庭成员的教唆、指使下造成第三者人身伤亡、财产损失导致的责任；</p>
            <p>（四）被保险人或其家庭成员所拥有、饲养、照管的动物造成的人身伤亡、财产损失；</p>
            <p>（五）被保险人因拥有、管理或使用各种机动车、电瓶车、电动自行车、船、飞行器导致的责任；</p>
            <p>（六）任何类型的传染病导致的责任、费用；</p>
            <p>（七）被保险人所有的、租借的、保管的或掌控下的财产的损坏或灭失；</p>
            <p>（八）被保险人参加潜水、滑雪、滑板、滑翔、冲浪、蹦极、热气球、跳伞、攀岩、漂流、探险活动、特技表演、赛马、赛车等高风险运动，或进行摔跤、柔道、拳击、武术、散打、空手道、跆拳道等搏击运动，以及进行前述运动前准备活动时导致的责任、费用；</p>
            <p>（九）被保险人应该承担的合同责任，但无合同存在时仍然应由被保险人承担的经济赔偿责任不在此限；</p>
            <p>（十）罚款、罚金及惩罚性赔款；</p>
            <p>（十一）间接损失；</p>
            <p>（十二）投保人、被保险人在投保之前已经知道或可以合理预见的索赔情况；</p>
            <p>（十三）本保险合同中载明的免赔额。</p>
            <p>【特约责任免除】</p>
            <p>下列物品的损失，保险人不负责赔偿：</p>
            <p>（一）金银、珠宝首饰或饰物；</p>
            <p>（二）移动电话（或称手机、蜂窝电话）；</p>
            <p>（三）手提电脑（或称手提便携式电脑、笔记本电脑）或个人商务助理设备（或称掌上电脑、PDA）；</p>
            <p>（四）图章、文件；</p>
            <p>（五）用于商业活动的物品或样品，动物、植物或食物；</p>
            <p>（六）机动车辆（及其附件）、摩托车、船、发动机或其他运输工具；</p>
            <p>（七）家具、古董、字画等无法确定价值的物品；</p>
            <p>（八）被保险人从他人租赁的设备，眼镜、隐形眼镜、助听器和假肢的遗失或损坏。</p>
            <p>【理赔材料】</p>
            <p>个人责任(第三者财产遗失及损坏)理赔材料:</p>
            <p>1、 理赔申请书；</p>
            <p>2、被保险人和受害人的身份证明；</p>
            <p>3、医护到家平台相关订单信息；</p>
            <p>4、三者财产、货物价值证明,损失、费用清单；</p>
            <p>5、事故证明 ；</p>
            <p>6、被保险人与受害人所签订的赔偿协议书或和解书;经判决或仲裁的,应提供判决文书或仲裁裁 决文书；</p>
            <p>7、保险金申请人所能提供的与确认保险事故的性质、原因、伤害程度等有关的其他证明和材料。</p>

            <p>个人责任(第三者人身伤害)理赔材料:</p>
            <p>1、理赔申请书；</p>
            <p>2、被保险人和受害人的身份证明；</p>
            <p>3、医护到家平台相关订单信息；</p>
            <p>4、造成受害人人身伤害的,应包括:受害人的病历、诊断证明、医疗费等医疗原始单据;受害人 的人身伤害程度证明:受害人伤残的,应当提供具备相关法律法规要求的伤残鉴定资格的医疗机构 出具的伤残程度证明;受害人死亡的,公安机关或医疗机构出具的死亡证明书; </p>
            <p>5、事故证明；</p>
            <p>6、被保险人与受害人所签订的赔偿协议书或和解书;经判决或仲裁的,应提供判决文书或仲裁裁 决文书；</p>
            <p>7、保险金申请人所能提供的与确认保险事故的性质、原因、伤害程度等有关的其他证明和材料 。</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "insurancePage"
    }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .wrapper {
        font-size: 26px;
        .content{
            padding: 20px;
            p{
               margin:8px 0;
            }
        }
        table{
            tr td{
                padding:6px;
            }
        }
    }
</style>