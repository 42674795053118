import { render } from "./insurancePage.vue?vue&type=template&id=7d811300&scoped=true"
import script from "./insurancePage.vue?vue&type=script&lang=js"
export * from "./insurancePage.vue?vue&type=script&lang=js"

import "./insurancePage.vue?vue&type=style&index=0&id=7d811300&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-7d811300"
/* hot reload */
if (module.hot) {
  script.__hmrId = "7d811300"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7d811300', script)) {
    api.reload('7d811300', script)
  }
  
  module.hot.accept("./insurancePage.vue?vue&type=template&id=7d811300&scoped=true", () => {
    api.rerender('7d811300', render)
  })

}

script.__file = "src/views/static/about/insurancePage.vue"

export default script